import { tv, VariantProps } from '@/utils/tv'

export const cigarBarVariant = tv({
  slots: {
    base: ['bg-cigarbar'],
    wrapper: ['max-w-7xl', 'mx-auto', 'px-6 lg:px-8'],
    container: [
      'flex justify-between items-center md:items-center gap-4 md:gap-6 md:min-h-[64px]'
    ],
    imgTextWrapper: [
      'flex justify-end items-end md:justify-center md:items-center gap-2 md:gap-6'
    ],
    textWrapper: ['my-3.5'],
    text: ['[&>b]:font-semibold [&>span>b]:text-white'],
    action: ['shrink-0 my-auto md:my-0'],
    image: ['hidden md:flex', 'w-14']
  }
})

export type CigarBarVariantProps = VariantProps<typeof cigarBarVariant>
